import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import EightyPercentPromoSignup from '../../components/EightyPercentPromoSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo title="GlassHive’s October promo" />
            <div className="october-promotion">
                <Container className="container-1">
                    <Row className="header-row">
                        <Col className="one-half">
                            <h1 className="white-text promo-header">
                                GlassHive’s October promo
                            </h1>
                            <h3 className="white-text promo-subheader">
                                Take advantage of this special offer!
                            </h3>
                        </Col>
                        <Col className="one-half promo-image-col">
                            <img
                                className="promo-image"
                                src={require('../../images/october-promotion/Group7590.png')}
                                alt="promo"
                            />
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../images/october-promotion/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Get started with GlassHive
                            </h4>
                            <p>
                                GlassHive is offering a special promotion for
                                the month of October!
                            </p>
                            <p>
                                We’re offering 80% off your first month when you
                                sign up for the pro package.
                            </p>
                            <p>
                                PLUS you’ll get a free 30-minute marketing and
                                sales consultation. And with our marketing
                                agency experience, you’ll definitely gain some
                                great insight.
                            </p>
                            <p>
                                Get started with GlassHive today and take your
                                sales and marketing to the next level!
                            </p>
                        </Col>
                        <Col className="three-fifth october-promo-form">
                            <EightyPercentPromoSignup />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
