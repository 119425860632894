import React from 'react';
import AppForm from '../AppForm';
import AppInput from '../AppInput';

import './style.scss';

const EightyPercentPromoSignup = () => {
    return (
        <div className="form-container">
            <AppForm
                explainerText={`
                <h3>Reach out to us to claim your promo</h3>
                <p class="centered-text white-text">A team member will reach out and send you a link to get your 80% off your first month, as well as scheduling a free consultation.</p>`}
                formName="80% Promo"
                submitBtnText="Claim your promo"
                formCategory="EightyPercentPromo"
            >
                <AppInput
                    fieldName="email"
                    type="email"
                    placeholder="Email"
                    requiredField
                />
            </AppForm>
        </div>
    );
};

export default EightyPercentPromoSignup;
